import React from "react"

import Layout from "../components/UI/Layout/layout"
import SEO from "../components/seo"
import SpaceTop from "../components/UI/SpaceTop"
import { Container } from "../components/UI/Grid"

const NotFoundPage = () => (
  
  <Layout>
    <SEO metaTitle="404: Not found" />
    <SpaceTop/>
    <Container>
      <h1>Seite wurde nicht gefunden</h1>
    </Container>
    
  </Layout>
)

export default NotFoundPage
